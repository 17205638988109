/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
import { Authentication, AuthServiceType } from '@movecloser/front-core'
import { Component, Mixins } from 'vue-property-decorator'

import { AllowedAttributes, Variant } from '../../../contexts'
import { Inject } from '../../../support'

import { AuthMixin, UserModel } from '../../auth/shared'
import BaseCompany from './base-company.mixin'

@Component<B2BProductMixin>({
})
class B2BProductMixin extends Mixins<AuthMixin & BaseCompany>(AuthMixin, BaseCompany) {
  @Inject(AuthServiceType, false)
  protected readonly authService?: Authentication<UserModel>

  public getQuantityStep (variant?: Pick<Variant<string>, 'attributes'> | null, requestedQuantity: number = 1): number {
    if (!variant || !variant.attributes || !('package_amount' in variant.attributes)) {
      return 0
    }

    if (this.isUserB2B) {
      const packageAmount = variant.attributes[AllowedAttributes.PackageAmount]

      if (typeof packageAmount !== 'string') {
        return 1
      }

      return parseInt(packageAmount ?? '', 10) || 1
    } else {
      return requestedQuantity
    }
  }
}

export default B2BProductMixin
