












































import { Component, Mixins, Prop } from 'vue-property-decorator'

import { BaseCartMixin, IBaseCart } from '../../../shared/mixins/base-cart.mixin'
import { CartAnalyticsMixin } from '../../../../shared/mixins/cart-analytics.mixin'
import { FullscreenLoader } from '../../../../shared/molecules/Loader'
import { IToastMixin, ToastMixin } from '../../../../shared'
import { SidesLayout } from '../../../../shared/molecules/SidesLayout'

import { CartSummary } from '../../../organisms/CartSummary'
import { SingleCartItem } from '../../../organisms/SingleCartItem'
import { translateToCartItem } from '../../../molecules/CartItem/CartItem.helpers'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<Basic>({
  name: 'Basic',
  components: { CartSummary, SidesLayout, SingleCartItem, FullscreenLoader }
})
export class Basic extends Mixins<IToastMixin, IBaseCart, CartAnalyticsMixin>(
  ToastMixin,
  BaseCartMixin,
  CartAnalyticsMixin
) {
  @Prop({ type: Boolean, required: false })
  public readonly shouldPreserveDeleted?: boolean

  @Prop({ type: Boolean, required: true })
  public readonly isCartReady!: boolean

  @Prop({ type: Function, required: true })
  public readonly hasError!: (action: string) => boolean

  @Prop({ type: Function, required: true })
  public readonly getError!: (action: string) => string

  @Prop({ type: Boolean, required: true })
  public readonly hasCartItems!: boolean

  @Prop({ type: Boolean, required: true })
  public readonly isCartItemPending!: boolean

  @Prop({ type: Function, required: true })
  public readonly onQuantityChange!: (uid: string, newQuantity: number, previousQuantity: number) =>
    Promise<void>

  @Prop({ type: Function, required: true })
  public readonly removeFromCart!: (uid: string) => Promise<void>

  @Prop({ type: Function, required: true })
  public readonly onIsLoadingHandler!: (value: boolean) => void

  @Prop({ type: Function, required: true })
  public readonly goToHome!: () => void

  public translateToCartItem = translateToCartItem
}

export default Basic
