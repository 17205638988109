
import { Component, Vue } from 'vue-property-decorator'

import { CompanyModel } from '../../profile/contracts/models'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<BaseCompany>({
  name: 'CompanyMixin'
})
export class BaseCompany extends Vue {
  public get company (): CompanyModel | null {
    return this.$store.getters['shared/getCompany']
  }
}

export default BaseCompany
