// Copyright © 2022 Move Closer

import { VueConstructor } from 'vue'

import Basic from './versions/Basic.vue'
import Enhanced from './versions/Enhanced.vue'
import { AnyObject } from '@movecloser/front-core'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CART_SUGGESTED_CAROUSEL_DEFAULT_CONFIG = {
  autoplay: false,
  centralize: false,
  delay: 2000,
  hasPagination: false,
  hasButtons: true,
  initialSlide: 0,
  inLoop: true,
  pauseOnHover: false,
  perPage: {
    mobile: 2,
    desktop: 4
  },
  spaceBetween: 8
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CART_VIEW_KEY = 'CartView'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum CartLayout {
  Basic = 'basic',
  Enhanced = 'enhanced'
}

export interface CartRestoredPayload {
  gifts: string[]
  uid: string
  newQuantity: number
  previousQuantity: number
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export type CarouselConfig = Record<string, string | number | boolean | AnyObject>

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface CartViewConfig {
  layout: string
  cartIcons: Array<string>
  cartItemSize: string
  shouldLoadGifts: boolean
  shouldLoadSamples: boolean
  shouldLoadSuggestedProducts: boolean
  shouldPreserveDeleted: boolean
  carouselConfig: CarouselConfig
  showEnhancedCouponMobile: boolean
  showPaymentsMethodsLabel: boolean
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const CART_VIEW_CONFIG_MAP: CartViewConfig = {
  layout: CartLayout.Enhanced,
  cartIcons: [],
  cartItemSize: '',
  shouldLoadGifts: false,
  shouldLoadSamples: false,
  shouldLoadSuggestedProducts: false,
  shouldPreserveDeleted: false, // todo: aelia: true
  carouselConfig: CART_SUGGESTED_CAROUSEL_DEFAULT_CONFIG,
  showEnhancedCouponMobile: true,
  showPaymentsMethodsLabel: true
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const cartViewComponent: Record<string, VueConstructor> = {
  [CartLayout.Basic]: Basic,
  [CartLayout.Enhanced]: Enhanced
}
