






















































































import { Component, Inject as VueInject, Mixins, Prop } from 'vue-property-decorator'

import { CartItemData, ProductData } from '../../../../../contexts'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

import { CartAnalyticsMixin } from '../../../../shared/mixins/cart-analytics.mixin'
import { FullscreenLoader } from '../../../../shared/molecules/Loader'
import { IToastMixin, ToastMixin } from '../../../../shared'
import { SidesLayout } from '../../../../shared/molecules/SidesLayout'

import { BaseCartMixin, IBaseCart } from '../../../shared/mixins/base-cart.mixin'
import { CartCoupons } from '../../../organisms/CartCoupons'
import { CartSummary } from '../../../organisms/CartSummary'
import { SingleCartItem } from '../../../organisms/SingleCartItem'
import { translateToCartItemEnhanced } from '../../../molecules/CartItem/CartItem.helpers'

import { ExtraItemsPicker } from '../partials/ExtraItemsPicker.vue'
import { PaymentOptions } from '../partials/PaymentOptions.vue'
import { PredictedDelivery } from '../partials/PredictedDelivery.vue'
import { ProgressDiscount } from '../partials/ProgressDiscount.vue'
import { CarouselConfig, CartViewConfig } from '../Cart.config'
import SuggestedProducts from '../partials/SuggestedProducts.vue'
import B2BProductMixin from '../../../../shared/mixins/b2b-product.mixin'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl> (original)
 */
@Component<Enhanced>({
  name: 'Enhanced',
  components: {
    CartCoupons,
    CartSummary,
    ExtraItemsPicker,
    PaymentOptions,
    PredictedDelivery,
    ProgressDiscount,
    SidesLayout,
    SingleCartItem,
    FullscreenLoader,
    SuggestedProducts
  }
})
export class Enhanced extends Mixins<IToastMixin & IBaseCart & CartAnalyticsMixin & B2BProductMixin>(
  ToastMixin,
  BaseCartMixin,
  CartAnalyticsMixin,
  B2BProductMixin
) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: Object, required: true })
  protected readonly config!: CartViewConfig

  @Prop({ type: Boolean, required: false })
  public readonly canPickGifts?: boolean

  @Prop({ type: Boolean, required: false })
  public readonly canPickSamples?: boolean

  @Prop({ type: Array, required: false })
  public readonly gifts?: Array<ProductData> | null

  @Prop({ type: Array, required: false })
  public readonly samples?: Array<ProductData> | null

  @Prop({ type: Array, required: false })
  public readonly suggestedProducts?: Array<ProductData>

  @Prop({ type: Boolean, required: false })
  public readonly shouldDisablePerItemQuantity?: boolean

  @Prop({ type: Boolean, required: false })
  public readonly shouldPreserveDeleted?: boolean

  @Prop({ type: Boolean, required: true })
  public readonly isCartReady!: boolean

  @Prop({ type: Function, required: true })
  public readonly hasError!: (action: string) => boolean

  @Prop({ type: Function, required: true })
  public readonly getError!: (action: string) => string

  @Prop({ type: Boolean, required: true })
  public readonly hasCartItems!: boolean

  @Prop({ type: Boolean, required: true })
  public readonly isCartItemPending!: boolean

  @Prop({ type: Function, required: true })
  public readonly onQuantityChange!: (uid: string, newQuantity: number, previousQuantity: number) =>
  Promise<void>

  @Prop({ type: Function, required: true })
  public readonly removeFromCart!: (uid: string) => Promise<void>

  @Prop({ type: Function, required: true })
  public readonly onIsLoadingHandler!: (value: boolean) => void

  @Prop({ type: Function, required: true })
  public readonly goToHome!: () => void

  public get cartItems () {
    return this.cart?.items.sort((a) => {
      if (a.isGift) {
        return 1
      } else if (a.isSample) {
        return 1
      }
      return -1
    })
  }

  public get paymentIcons (): string[] {
    if (!this.config.hasOwnProperty('cartIcons')) {
      return []
    }

    if (this.config.cartIcons.length === 0) {
      return []
    }

    return this.config.cartIcons
  }

  public get carouselConfig (): CarouselConfig {
    return this.config.carouselConfig
  }

  public get showEnhancedCouponMobile (): boolean {
    if (!this.config.hasOwnProperty('showEnhancedCouponMobile')) {
      return false
    }

    return this.config.showEnhancedCouponMobile
  }

  public get showPaymentsMethodsLabel (): boolean {
    if (!this.config.hasOwnProperty('showPaymentsMethodsLabel')) {
      return false
    }

    return this.config.showPaymentsMethodsLabel
  }

  public get showProgressDiscount (): boolean {
    return (this.hasCartItems && !!this.siteService.getProperty('showProgressDiscount'))
  }

  public getQuantityStepForItem (item: CartItemData): number {
    const variant = Object.values(item.product?.variants)[0]

    return this.getQuantityStep(variant)
  }

  public onItemResign (): void {
    this.$emit('onItemResign')
  }

  public onItemSelect (value: { product: ProductData | null }): void {
    this.$emit('onItemSelect', value)
  }

  public onUpdateCartRequest () {
    if (this.cart) {
      /**
       * FIXME: available this.refreshCart() method does not working properly
       */
      window.location.reload()
    }
  }

  public translateToCartItem = translateToCartItemEnhanced
}

export default Enhanced
