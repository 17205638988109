
































import { CartDiscount } from '../../../../../contexts'
import { Component, Mixins } from 'vue-property-decorator'

import { BaseCartMixin, IBaseCart } from '../../../shared/mixins/base-cart.mixin'
import { CartAnalyticsMixin } from '../../../../shared/mixins/cart-analytics.mixin'
import { Marker } from '../../../../../dsl/atoms/CustomProgressBar'

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
@Component<ProgressDiscount>({
  name: 'ProgressDiscount',
})
export class ProgressDiscount extends Mixins<IBaseCart & CartAnalyticsMixin>(
  BaseCartMixin,
  CartAnalyticsMixin
) {
  public get discountsRanges (): CartDiscount[] {
    if (!this.cart) {
      return []
    }

    return this.cart?.getDiscountsRanges()
  }

  public get maxValue (): number {
    return this.cart?.getDiscountsRanges()?.at(-1)?.amount?.value ?? 0
  }

  public get totalToPercentage (): number {
    if (!this.cart) {
      return 0
    }

    return this.cart?.getSubtotalNetPrice() * 100 / this.maxValue
  }

  public get currentRangeDiscount (): CartDiscount {
    const currentRangeDiscount = this.cart?.getDiscountsRanges()
      .filter(range => (this.cart?.getSubtotalNetPrice() ?? 0) >= range.amount.value).at(-1) ?? null

    if (currentRangeDiscount === null) {
      return {
        amount: {
          value: 0,
          currency: 'PLN'
        },
        label: '0%'
      }
    }

    return currentRangeDiscount
  }

  public get nextRangeDiscount (): {nextDiscount: string; discountLack: number} | null {
    const currentRangeDiscountIndex = this.discountsRanges
      .findIndex(range => range.amount.value === this.currentRangeDiscount.amount.value) ?? null

    if (this.discountsRanges.length - 1 === currentRangeDiscountIndex) {
      return null
    }

    const nextRangeDiscount = this.discountsRanges[currentRangeDiscountIndex + 1]
    return {
      nextDiscount: nextRangeDiscount.label,
      discountLack: nextRangeDiscount.amount.value - (this.cart?.getSubtotalNetPrice() ?? 0)
    }
  }

  public get markers (): Array<Marker> {
    if (!this.cart) {
      return []
    }

    return this.cart?.getDiscountsRanges().map((discount) => {
      return {
        label: discount.label,
        value: discount.amount.value * 100 / this.maxValue
      }
    })
  }
}

export default ProgressDiscount
