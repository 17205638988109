

















import { Component, Mixins } from 'vue-property-decorator'

import { monthNamesRegistry } from '../../../../shared/contracts/months'
import { DeliveryDateMixin, IDeliveryDate } from '../../../../shared/mixins/delivery-date.mixin'
import { ISiteService, SiteServiceType } from '../../../../../contexts'
import { Inject } from '../../../../../support'
import { showDeliveryTimer } from '../../../../shared/support/delivery-timer'
import { ShippingTimerData } from '../../../../products/organisms/ProductHeader'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<PredictedDelivery>({
  name: 'PredictedDelivery',
  created () {
    this.initTimer()
  }
})
export class PredictedDelivery extends Mixins<IDeliveryDate>(DeliveryDateMixin) {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  public showTimer: boolean = false

  public get deliveryDateTimeDesc (): string {
    if (!this.hasTimer) {
      return ''
    }

    const deliveryWeekday = this.getDeliveryWeekdayLabel()

    return this.$t('front.checkout.views.CartView.enhanced.deliveryDescription', {
      time: this.maxOrderHours + ':00',
      day: deliveryWeekday.toLowerCase()
    }).toString()
  }

  protected formatDate (date: Date): string {
    const month = date.getMonth()
    const day = date.getDate()
    const weekday = this.getWeekday(date)

    const localePath = 'front.shared.mixins.deliveryDateMixin'
    const weekdayString = this.$t(`${localePath}.days.${weekday}`)
    const monthString = this.$t(`${localePath}.months.${monthNamesRegistry[month]}`)

    return `${weekdayString}, ${day} ${monthString}`
  }

  protected initTimer (): void {
    if (!this.siteService) {
      return
    }

    const shippingTimer = this.siteService.getProperty('shippingTimer') as ShippingTimerData | undefined
    const { shouldShowTimer, maxOrderHours } = showDeliveryTimer(shippingTimer)

    this.showTimer = shouldShowTimer

    if (maxOrderHours) {
      this.maxOrderHours = maxOrderHours
    }
  }
}

export default PredictedDelivery
